body.demo-examples {
  background: #fbfcfc; }
  body.demo-examples .demo-wrapper {
    font-size: 1rem; }
  body.demo-examples .demo-header {
    position: relative;
    display: flex; }
  body.demo-examples .demo-heading {
    font-size: 1.75rem;
    margin: 0;
    margin-right: auto;
    transition: font-size .25s; }
  body.demo-examples .docs-brief-intro {
    padding: 1rem 1.5rem;
    border-left: 5px solid #39527b; }
  body.demo-examples .card {
    border: none;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); }

.bd-example {
  padding: 0;
  border: none;
  margin-bottom: 1.5rem; }

#contents-wrapper {
  position: relative; }
  .stuck #contents-wrapper {
    position: fixed;
    top: 66px;
    right: 0;
    z-index: 999; }
    .stuck #contents-wrapper #contents-heading {
      background: #fff;
      box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.08); }
  #contents-wrapper #contents-heading {
    cursor: pointer;
    margin: 0;
    padding: 1rem; }
  #contents-wrapper #contents-index {
    position: absolute;
    z-index: 999;
    top: 3.5rem;
    min-width: 260px;
    right: 0;
    max-height: 380px;
    overflow: auto;
    display: none;
    background: #fff;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.08); }
    .stuck #contents-wrapper #contents-index {
      top: 100%; }
  #contents-wrapper #contents-list {
    padding: 1rem;
    margin: 0;
    margin-left: 1.5rem; }
    #contents-wrapper #contents-list li {
      margin-left: 0;
      transition: margin-left .3s; }
    #contents-wrapper #contents-list li:hover {
      margin-left: .5rem; }
